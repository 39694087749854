import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import * as organizationApi from "../../api/organization";
import Home from "../../components/Home";
import { Organization } from "../../types/Organization";

const OrganisationHomePage = () => {
  const router = useRouter();
  const [organization, setOrganization] = useState<Organization>();

  useEffect(() => {
    if (router.query.organizationName) {
      organizationApi
        .getOrganizationBySlug(router.query.organizationName as string)
        .then((res) => {
          setOrganization({ ...res.data });
        })
        .catch(() => {
          router.push("/");
        });
    }
  }, [router.query]);

  const redirectToSignIn = () => {
    router.push("/sign-in");
  };

  return <Home redirectToSignIn={redirectToSignIn} organisation={organization} />;
};

export default OrganisationHomePage;
